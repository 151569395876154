*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

body {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

a {
  text-decoration: none;
}

a.links {
  display: none;
}

.content {
  background: #f7f7f7;
  border: 1px solid #0009;
  border-radius: 5px;
  margin: 0 auto;
  padding: 18px 5% 10px;
  position: relative;
  width: 500px;
}

h1 {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  padding-bottom: 35px;
}

h1:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 10px;
  background: -webkit-linear-gradient(
    to left,
    rgba(147, 184, 189, 0) 0%,
    rgba(0, 0, 0, 0.6) 20%,
    #7f7c7c 53%,
    rgba(0, 0, 0, 0.6) 79%,
    rgba(147, 184, 189, 0) 100%
  );
  background: linear-gradient(
    to left,
    rgba(147, 184, 189, 0) 0%,
    rgba(0, 0, 0, 0.6) 20%,
    rgba(0, 0, 0, 0.6) 53%,
    rgba(0, 0, 0, 0.6) 79%,
    rgba(147, 184, 189, 0) 100%
  );
}

p {
  margin-bottom: 15px;
}

p:first-child {
  margin: 0px;
}

label {
  color: #405c60;
  position: relative;
}

.txtAtencao {
  background-color: #fdd;
  border-left: 6px solid #f44336;
  color: #d8000c;
  font-size: 11px;
  line-height: 1.3;
  padding: 20px 0 20px 0;
  text-align: center;
  white-space: pre-wrap;
}

@media screen and (max-width: 768px) {
  .txtAtencao {
    font-size: 12px;
    line-height: 13px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

::-webkit-input-placeholder {
  color: #bebcbc;
  font-style: italic;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #bebcbc;
  font-style: italic;
}

input {
  outline: none;
}

input:not([type="checkbox"]) {
  width: 95%;
  margin-top: 4px;
  padding: 10px;
  border: 1px solid #b2b2b2;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(168, 168, 168, 0.6) inset;
  box-shadow: 0px 1px 4px 0px rgba(168, 168, 168, 0.6) inset;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.txtButton[type="submit"] {
  width: 100% !important;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 5px;
  color: #fff;
  font-size: 20px;
  border: 1px solid #fff;
  margin-bottom: 10px;
  text-shadow: 0 1px 1px #333;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  transition: all 0.2s linear;
}

.txtButton[type="submit"]:hover {
  background: rgb(0 0 0 / 43%);
}

.link {
  position: absolute;
  background: #e1eaeb;
  color: #7f7c7c;
  left: 0px;
  height: 20px;
  width: 440px;
  padding: 17px 30px 20px 30px;
  font-size: 16px;
  text-align: right;
  border-top: 1px solid #dbe5e8;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}

.link a {
  font-weight: bold;
  background: #f7f8f1;
  padding: 6px;
  color: rgb(29, 162, 193);
  margin-left: 10px;
  border: 1px solid #cbd518;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.link a:hover {
  color: #39bfd7;
  background: #f7f7f7;
  border: 1px solid #4ab3c6;
}

#acesso {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background: #f7f7f7;
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
}

.content #acesso {
  z-index: 2;
  animation-name: fadeInLeft;
  animation-delay: 0.1s;
}

#registro:target ~ .content #acesso {
  animation-name: fadeOutLeft;
}

@media screen and (max-width: 768px) {
  .content,
  .link {
    margin: 0 auto;
    width: 85%;
  }
}

/* estilo do banner de consentimento */
.location-consent-banner {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.location-consent-banner p {
  margin-bottom: 10px;
  font-size: 14px;
}

.location-consent-banner button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.location-consent-banner button:hover {
  background-color: #222222;
  transition: 0.5s;
}